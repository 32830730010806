import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
// Calendar UI Module
import { CalendarModule } from 'ion2-calendar';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChartsModule } from 'ng2-charts';
import { ConfirmBuyPageModule} from './modals/confirm-buy/confirm-buy.module';
import {DatePipe} from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicSelectableModule } from 'ionic-selectable';
import {AddToLeadsPageModule} from "./modals/add-to-leads/add-to-leads.module";
import {httpInterceptorProviders} from './http-interceptor';
import { FCM } from '@ionic-native/fcm/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
// import {DynamicFormComponent} from "./component/dynamic-form/dynamic-form.component";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    CalendarModule,
    ConfirmBuyPageModule,
    IonicSelectableModule,
    AddToLeadsPageModule,
    ChartsModule],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, 
    DatePipe,
      FCM,
    FirebaseX,
    Camera,
    File,
    FilePath,
    FileTransfer,
    StatusBar,
    SplashScreen,
    InAppBrowser,
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {}
