import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {MotorPage} from "../motor/motor.page";
import {Router} from "@angular/router";

@Component({
  selector: 'app-products',
  templateUrl: './products.page.html',
  styleUrls: ['./products.page.scss'],
})
export class ProductsPage implements OnInit {

  constructor(protected modalCtrl: ModalController,
              protected router: Router
              ) { }

  ngOnInit() {
  }
  async getMotor() {
    await this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: MotorPage,
      backdropDismiss: true,
      cssClass: 'productCss',
    });
    modal.onDidDismiss()
        .then((data) => {
          if (data.data) {
            console.log(data.data);
          }
        });
    await modal.present();
  }
  async getHealth() {
    await this.modalCtrl.dismiss();
    await this.router.navigateByUrl('health-quote-enquiry');
  }
  async cancel() {
    await this.modalCtrl.dismiss();
  }

}
