import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {Storage} from "@ionic/storage";

@Component({
  selector: 'app-motor',
  templateUrl: './motor.page.html',
  styleUrls: ['./motor.page.scss'],
})
export class MotorPage implements OnInit {

  constructor(protected modalCtrl: ModalController,
              protected storage: Storage,
              protected router: Router
  ) { }

  ngOnInit() {
    this.storage.create();
  }
  async getQuoteEnquiry(type) {
    await this.storage.remove('selectedParams');
    await this.storage.remove('params');
    await this.storage.set('selectedParams', '');
    await this.storage.set('params', '');
    await this.storage.set('vehicle_type', type);
    await this.modalCtrl.dismiss();
    await this.router.navigateByUrl('quote-enquiry');
  }
  async cancel() {
    await this.modalCtrl.dismiss();
  }

}
