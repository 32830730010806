import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ApiServiceService} from './api-service.service';
import {Storage} from '@ionic/storage';
import {HttpClient} from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KycVerificationService {
  protected baseUrl: string;
  headers;
  options;
  private _userKycDetails = new BehaviorSubject<[]>([])
  constructor(protected api: ApiServiceService,protected storage: Storage, protected httpClient: HttpClient) {
    this.baseUrl = environment.apiBaseUrl;
    this.storage.create();
    
   }
    getDetailsByIFSC(code) {
        return this.httpClient.get('https://ifsc.razorpay.com/' + code)
            .pipe(
                map((d: any) => d)
            );
    }
    /**
     * get experiences list
     */
    getExperiences() {
        return this.api.get('pos/experiences')
            .pipe(
                map((d: any) => d.data)
            );
    }

    /**
     * get source of income list
     */
    getSourceofIncomes() {
        return this.api.get('pos/source-of-incomes')
            .pipe(
                map((d: any) => d.data)
            );
    }
/**
 * Create basic information
 * @param userObj
 */
  addBasicDetails(userObj) {
    // this.storage.get('access_token').then(data=>{
    //   console.log('Token:', data);
    // })
    return this.api.post('pos/register/create-basic-information' , userObj)
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Create basic information
 * @param userObj
 */
 updateBasicDetails(userObj) {
  // this.storage.get('access_token').then(data=>{
  //   console.log('Token:', data);
  // })
  return this.api.put('pos/register/update-basic-information' , userObj)
      .pipe(
          map((d: any) => d.data)
      );
}

/**
 * Create basic information
 * @param businessObj
 */
   addBusinessDetails(businessObj) {

    return this.api.post('pos/register/create-business-information' , businessObj)
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Create basic information
 * @param businessObj
 */
 updateBusinessDetails(businessObj) {

  return this.api.put('pos/register/update-business-information' , businessObj)
      .pipe(
          map((d: any) => d.data)
      );
}

/**
 * Create basic information
 * @param identityObj
 */
  addIdentityDetails(identityObj) {

    return this.api.post('pos/register/create-identity-information' , identityObj)
        .pipe(
            map((d: any) => d.data)
        );
  }

/**
 * Create basic information
 * @param identityObj
 */
 updateIdentityDetails(identityObj) {

  return this.api.put('pos/register/update-identity-information' , identityObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Create basic information
 * @param bankObj
 */
 addBankDetails(bankObj) {

  return this.api.post('pos/register/create-bank-information' , bankObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Create basic information
 * @param bankObj
 */
 updateBankDetails(bankObj) {

  return this.api.put('pos/register/update-bank-information' , bankObj)
      .pipe(
          map((d: any) => d.data)
      );
}

/**
 * Create basic information
 * @param educationObj
 */
 addEducationalDetails(educationObj) {

  return this.api.post('pos/register/create-education-information' , educationObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

/**
 * Create basic information
 * @param educationObj
 */
 updateEducationalDetails(educationObj) {

  return this.api.put('pos/register/update-education-information' , educationObj)
      .pipe(
          map((d: any) => d.data)
      );
  }

    /**
     * Create basic information
     * @param otherDocObj
     */
    updateOtherDocDetails(otherDocObj) {

        return this.api.put('pos/register/update-other-document' , otherDocObj)
            .pipe(
                map((d: any) => d.data)
            );
    }

  /**
   * Get KYC Infromation
   */
  getKYCInformation() {
    return this.api.get('pos/register/get-kyc-information')
      .pipe(
          map((d: any) => d.data)
      );
  }
  get kycInfo$() {
    return this._userKycDetails.asObservable()
  }
}
