import {Component, QueryList, ViewChildren} from '@angular/core';
import {
    ActionSheetController, IonRouterOutlet,
    MenuController,
    ModalController,
    Platform,
    PopoverController,
    ToastController
} from "@ionic/angular";
import {Router} from "@angular/router";
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {KycVerificationService} from "./services/kyc-verification.service";
import {Storage} from "@ionic/storage-angular";
import { FCM } from '@ionic-native/fcm/ngx';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import {FcmService} from "./services/fcm.service";
import {ProductsPage} from "./modals/products/products.page";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  appPages: any = [];
  status;
  kycStatus;
  username = '';
  posIdentity = '';
    // set up hardware back button event.
    lastTimeBackPress = 0;
    timePeriodToExit = 2000;
    @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  constructor(public menuCtrl: MenuController,
              private platform: Platform,
              private splashScreen: SplashScreen,
              private statusBar: StatusBar,
              protected kycService: KycVerificationService,
              protected storage: Storage,
              private fcm: FCM,
              protected fcmService: FcmService,
              private firebase: FirebaseX,
              protected router: Router,
              protected actionSheetCtrl: ActionSheetController,
              protected toast: ToastController,
              protected modalCtrl: ModalController,
              protected popoverCtrl: PopoverController,
              ) {
    this.initializeApp();
  }
  initializeApp() {
    this.storage.create();
    this.platform.ready().then(() => {
      this.statusBar.styleLightContent()
      this.splashScreen.hide();
      this.statusBar.backgroundColorByHexString('#09a223');
      this.fcmSettings();
      this.backButtonEvent();
      Promise.all([this.storage.get('access_token'), this.storage.get('username'), this.storage.get('pos_identity'), this.storage.get('impersonate')])
        .then(values => {
            console.log(values[3]);
            if(values[3] === false) {
                if (values[0]) {
                    this.storage.set('access_token', values[0]);
                    // this.getKYCSLatestStatus();
                    this.router.navigateByUrl('cover-page');
                } else {
                    this.router.navigateByUrl('welcome');
                } 
            }
            
            if (values[1]) {
                this.username = values[1];
            }
            if (values[2]) {
                this.posIdentity = values[2];
            }
        });
    });
  }
    // active hardware back button
    backButtonEvent() {
        this.platform.ready().then(() => {
            if (!this.platform.is('desktop') || !this.platform.is('mobileweb')) {
                this.platform.backButton.subscribe(async () => {
                    // close action sheet
                    try {
                        const element = await this.actionSheetCtrl.getTop();
                        if (element) {
                            element.dismiss();
                            return;
                        }
                    } catch (error) {
                    }

                    // close popover
                    try {
                        const element = await this.popoverCtrl.getTop();
                        if (element) {
                            element.dismiss();
                            return;
                        }
                    } catch (error) {
                    }

                    // close modal
                    try {
                        const element = await this.modalCtrl.getTop();
                        if (element) {
                            element.dismiss();
                            return;
                        }
                    } catch (error) {
                        console.log(error);
                    }

                    this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
                        console.log(this.router.url);
                        if (outlet && outlet.canGoBack()) {
                            outlet.pop();
                        } else if (this.router.url === '/overview' || this.router.url === '/leads' ||
                            this.router.url === '/business' || this.router.url === '/earnings') {
                            if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
                                navigator['app'].exitApp();
                            } else {
                                this.toast.create({
                                    message: `Press back again to exit App.`,
                                    duration: 2000
                                }).then(t => t.present());
                                this.lastTimeBackPress = new Date().getTime();
                            }
                        }
                    });
                });
            }
        });

    }
    fcmSettings() {
        if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
            // this.fcmService.receiveMessage();
        } else {
            this.firebase.clearAllNotifications();
            this.firebase.onMessageReceived().subscribe(data => {
                const details = JSON.parse(data.latest_kyc_status);
                console.log(details);
                if (details.kyc_status_id) {
                    this.switchBasedOnKYC(details.kyc_status_id);
                }
            });
        }
    }
  async getKYCSLatestStatus() {
    this.kycService.getKYCInformation()
        .subscribe((result) => {
            if (result.latest_kyc_status.kyc_status_id) {
                this.switchBasedOnKYC(result.latest_kyc_status.kyc_status_id);
            }
        });
  }
  switchBasedOnKYC(kyc_status_id) {
      let navigationPath;
      switch(kyc_status_id) { // (1) Not Submitted, (2) Pending Approval, (3) Approved, (4) Rejected.
          case 1:
               this.storage.set('coverStatus', 'beforeUploadKyc');
               this.storage.set('pendingStatus', 'notSubmitted');
              navigationPath = 'cover-page';
              break;
          case 2:
               this.storage.set('coverStatus', 'beforeUploadKyc');
               this.storage.set('pendingStatus', 'pending');
              navigationPath = 'cover-page';
              break;
          case 3:
               this.storage.set('coverStatus', 'training');
              // navigationPath = 'coming-soon-training';
              navigationPath = 'cover-page';
              break;
          case 4:
               this.storage.set('coverStatus', 'beforeUploadKyc');
               this.storage.set('pendingStatus', 'rejected');
              navigationPath = 'cover-page';
              break;
          case 5:
               this.storage.set('coverStatus', 'beforeUploadKyc');
               this.storage.set('pendingStatus', 'pending');
              navigationPath = 'cover-page';
              break;
          case 6:
               this.storage.set('coverStatus', 'exam');
              navigationPath = 'cover-page';
              break;
          case 7:
               this.storage.set('coverStatus', 'training');
              navigationPath = 'cover-page';
              break;
          case 8:
               this.storage.set('coverStatus', 'onBoardingCompleted');
              navigationPath = 'my-account';
              break;
          case 9:
               this.storage.set('coverStatus', 'exam');
              navigationPath = 'cover-page';
              break;
        case 10: //exam failed
                this.storage.set('coverStatus', 'examFailed');
                navigationPath = 'cover-page';
                break;
          default:
               this.storage.set('coverStatus', 'beforeUploadKyc');
               this.storage.set('pendingStatus', 'notSubmitted');
              navigationPath = 'cover-page';
              break;
      }
       this.router.navigateByUrl(navigationPath);
  }
  /**
   * close button for closing menu
   */
  async closeMenu(option = false) {
      await this.storage.remove('access_token');
      await this.menuCtrl.toggle();
      if(option) {
          const modal = await this.modalCtrl.create({
              component: ProductsPage,
              backdropDismiss: true,
              cssClass: 'productCss',
          });
          modal.onDidDismiss()
              .then((data) => {
                  if (data.data) {
                      console.log(data.data);
                  }
              });
          await modal.present();
      }
  }
}